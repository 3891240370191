.BreadshelfList {
    height: 100%;
    width: 48%;
    display: block;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.BreadshelfListMobile {
    height: 48%;
    width: 100%;
    display: block;
    text-align: left;
    margin-top: 1rem;
}

.Scrollbar > :first-child{
    margin-bottom: 0px !important;
    margin-right: -18px !important;
}